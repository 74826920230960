<template>
  <div
    class="modal fade"
    id="playlist_modal"
    tabindex="-1"
    aria-hidden="true"
    role="dialog"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content" v-loading="dataLoading">
        <div class="modal-header">
          <h2>Add to Playlist</h2>
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>
        <Form ref="formRef" class="form" :validation-schema="validationSchema">
          <div class="modal-body py-2 px-lg-17">
            <div
              class="scroll-y me-n7 pe-7 pt-10 pb-10"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_create_api_key_header"
              data-kt-scroll-wrappers="#kt_modal_create_api_key_scroll"
              data-kt-scroll-offset="300px"
            >
              <div class="mb-5 fv-row">
                <label class="required fs-5 fw-bold mb-2">Playlist Name</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Your Playlist Name"
                  name="playlistName"
                  v-model="playlistName"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="watchlistName" />
                  </div>
                </div>
              </div>
              <div class="mb-15">
                <span class="text-center d-block fw-bold fs-4"
                  >Or Add to an Existing Playlist</span
                >
                <div class="mh-375px scroll-y me-n7 pe-7">
                  <template
                    v-for="(playlist, index) in userPlaylists"
                    :key="index"
                  >
                    <div
                      class="
                        d-flex
                        flex-stack
                        py-5
                        border-bottom border-gray-300 border-bottom-dashed
                      "
                    >
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-35px symbol-circle">
                          <img
                            alt="Pic"
                            :src="getAvatarImage(playlist.images)"
                          />
                        </div>
                        <div class="ms-6">
                          <a
                            type="button"
                            class="
                              d-flex
                              align-items-center
                              fs-5
                              fw-bolder
                              text-dark text-hover-primary
                              cursor-pointer
                            "
                            @click="addTrackToPlaylist(playlist.id)"
                          >
                            {{ playlist.name }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-center">
            <button
              ref="addWatchlistModalCloseButton"
              type="reset"
              class="btn btn-white me-3"
              data-bs-toggle="modal"
              data-bs-target="#playlist_modal"
            >
              Close
            </button>
            <button
              ref="submitButtonRef"
              type="submit"
              :disabled="!playlistName.length"
              class="btn btn-primary"
              @click.prevent="createPlaylist"
            >
              <span class="indicator-label"> Create Playlist </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import useAvatarImage from "../../common/composables/useAvatarImage";
import useSpotifyApi from "../../common/composables/useSpotifyApi";

export default defineComponent({
  name: "AddToPlaylistModal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props, context) {
    const dataLoading = ref(true);
    const formRef = ref(null);
    const { getAvatarImage } = useAvatarImage();
    const playlistName = ref("");
    const submitButtonRef = ref(null);
    const store = useStore();
    const userPlaylists = ref([]);
    const user = computed(
      () => store.getters["SpotifyAccessTokenHelperModule/getSpotifyUser"]
    );

    const addTrackToPlaylist = (playlistId) => {
      dataLoading.value = true;
      context.emit("add-track", playlistId);
    };

    const createPlaylist = () => {
      dataLoading.value = true;
      context.emit("create-playlist", playlistName.value);
    };

    const fetchUserPlaylists = async () => {
      try {
        dataLoading.value = true;
        const spotifyApi = await useSpotifyApi(store);
        const { body } = await spotifyApi.getUserPlaylists();
        userPlaylists.value = body.items.filter(
          (playlist) => playlist.owner.id === user.value.id
        );
      } catch (e) {
        console.error(e);
      } finally {
        dataLoading.value = false;
      }
    };

    const validationSchema = Yup.object().shape({
      playlistName: Yup.string().required().label("Playlist Name"),
    });
    return {
      addTrackToPlaylist,
      createPlaylist,
      dataLoading,
      formRef,
      fetchUserPlaylists,
      getAvatarImage,
      playlistName,
      validationSchema,
      submitButtonRef,
      userPlaylists,
    };
  },
});
</script>
<style lang="scss">
.disabledLink {
  pointer-events: none;
}
.watch-lists-tags {
  .el-select__input {
  }
  .el-input__inner {
    height: 42px !important;
    background-color: #f5f8fa;
    border: none !important;
    border-radius: 0.475rem !important;
  }
  .el-tag {
    background-color: white !important;
  }
}
</style>
