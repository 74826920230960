<template>
  <div class="d-flex justify-content-center" :id="id + index">
    <div class="card flex-grow-1" ref="elementRef" :id="index + id">
      <div class="card-body">
        <el-skeleton :loading="loading" animated>
          <template #default>
            <el-row :gutter="20">
              <el-col :span="18">
                <div class="d-flex">
                  <div>
                    <el-avatar
                      :size="150"
                      shape="circle"
                      :src="getAvatarImage(artist.images)"
                    ></el-avatar>
                  </div>
                  <div
                    class="
                      d-flex
                      flex-column
                      artist-details
                      justify-content-center
                    "
                  >
                    <h2>{{ artist.name }}</h2>
                    <div>
                      <span
                        v-for="(genre, index) in artist.genres"
                        :key="index"
                      >
                        {{ genre + " " }}
                      </span>
                    </div>
                    <span class="subheading mt-2"
                      >{{ artist.followers.total }} followers</span
                    >
                  </div>
                </div>
                <div class="mt-10">
                  <span class="subheading tracksImages">TOP TRACKS</span>
                  <div class="mt-1">
                    <el-avatar
                      @click="
                        addComponentToView([
                          { component: 'DiscoverAlbum', id: track.id },
                        ])
                      "
                      :id="generateUUID()"
                      @mouseenter="
                        playTrack(
                          $event.target.id,
                          track.artists.length ? track.artists[0].name : 'N/A',
                          track.album.name,
                          track.album.images,
                          track.preview_url
                        )
                      "
                      @mouseleave="stopTrack"
                      class="tracksImages cursor-pointer"
                      :size="50"
                      v-for="track in topTracks"
                      :key="track.id"
                      shape="square"
                      :src="getAvatarImage(track.album.images)"
                    />
                  </div>
                </div>
                <div class="mt-10">
                  <span class="subheading tracksImages">ALBUMS</span>
                  <div class="mt-1">
                    <el-avatar
                      @click="
                        addComponentToView([
                          {
                            component: 'DiscoverAlbumWithTracks',
                            id: album.id,
                          },
                        ])
                      "
                      :id="generateUUID()"
                      @mouseenter="getAlbumTracks($event.target.id, album)"
                      @mouseleave="stopTrack"
                      class="tracksImages cursor-pointer"
                      :size="50"
                      v-for="album in albums"
                      :key="album.id"
                      shape="square"
                      :src="getAvatarImage(album.images)"
                    />
                  </div>
                </div>
                <div class="mt-10">
                  <span class="subheading tracksImages"
                    >SINGLES AND COMPILATIONS</span
                  >
                  <div class="mt-1">
                    <el-avatar
                      @click="
                        addComponentToView([
                          {
                            component: 'DiscoverAlbumWithTracks',
                            id: singleAndCompilation.id,
                          },
                        ])
                      "
                      :id="generateUUID()"
                      @mouseenter="
                        getAlbumTracks($event.target.id, singleAndCompilation)
                      "
                      @mouseleave="stopTrack"
                      class="tracksImages cursor-pointer"
                      :size="30"
                      v-for="singleAndCompilation in singlesAndCompilations"
                      :key="singleAndCompilation.id"
                      shape="square"
                      :src="getAvatarImage(singleAndCompilation.images)"
                    />
                  </div>
                </div>
                <div class="mt-10">
                  <span class="subheading tracksImages">APPEARS ON</span>
                  <div class="mt-1">
                    <el-avatar
                      @click="
                        addComponentToView([
                          {
                            component: 'DiscoverAlbumWithTracks',
                            id: appearences.id,
                          },
                        ])
                      "
                      :id="generateUUID()"
                      @mouseenter="
                        getAlbumTracks($event.target.id, appearences)
                      "
                      @mouseleave="stopTrack"
                      class="tracksImages cursor-pointer"
                      :size="30"
                      v-for="appearences in appearsOn"
                      :key="appearences.id"
                      shape="square"
                      :src="getAvatarImage(appearences.images)"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="mt-10">
                  <span class="subheading tracksImages">RELATED ARTISTS</span>
                  <div class="mt-1">
                    <el-avatar
                      @click="
                        addComponentToView([
                          {
                            component: 'DiscoverArtistDetail',
                            id: artist.id,
                          },
                        ])
                      "
                      :id="generateUUID()"
                      @mouseenter="getArtistTracks($event.target.id, artist)"
                      @mouseleave="stopTrack"
                      class="tracksImages cursor-pointer"
                      :size="50"
                      v-for="artist in relatedArtists"
                      :key="artist.id"
                      fill="contain"
                      shape="circle"
                      :src="getAvatarImage(artist.images)"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
          </template>
        </el-skeleton>
      </div>
    </div>
    <div v-if="!loading">
      <DiscoverActionPanel
        :is-followed="isArtistFollowedByUser"
        :url="artist.external_urls.spotify"
        :content="
          isArtistFollowedByUser
            ? 'You follow this artist'
            : 'Follow this artist'
        "
        @follow="followArtist"
        :icon="
          isArtistFollowedByUser
            ? '/media/icons/followed.svg'
            : '/media/icons/add_to_playlist_white.svg'
        "
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

import DiscoverActionPanel from "./DiscoverActionPanel";
import useSpotifyApi from "../../common/composables/useSpotifyApi";
import useUUID from "../../common/composables/useUUID";
import useAvatarImage from "../../common/composables/useAvatarImage";

export default {
  name: "DiscoverArtistDetail",
  components: { DiscoverActionPanel },
  props: {
    id: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const artist = ref();
    const albums = ref([]);
    const appearsOn = ref([]);
    const elementRef = ref(null);
    const { generateUUID } = useUUID();
    const { getAvatarImage } = useAvatarImage();
    const isArtistFollowedByUser = ref(false);
    const loading = ref(true);
    const relatedArtists = ref([]);
    const store = useStore();
    const singlesAndCompilations = ref([]);
    const topTracks = ref([]);

    onMounted(async () => {
      await getComponentData(props.id);
    });

    const getComponentData = async (id) => {
      loading.value = true;
      const artistResponse = await fetchArtistData(id);
      const artistAlbumsResponse = await fetchArtistAlbumsData(id);
      await checkIsArtistFollowed(id);
      setValues(artistResponse, artistAlbumsResponse);
      loading.value = false;
      store.dispatch("DiscoverModule/scrollToElement", props.id + props.index);
    };

    const addComponentToView = (componentData) => {
      context.emit("add-component", componentData);
    };

    const checkIsArtistFollowed = async (id) => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        const { body } = await spotifyApi.isFollowingArtists([id]);
        isArtistFollowedByUser.value = body[0];
      } catch (e) {
        console.error(e);
      }
    };

    const fetchArtistAlbumsData = async (id) => {
      const spotifyApi = await useSpotifyApi(store);
      try {
        const [albums, singlesAndCompilation, appearsOn] = await Promise.all([
          spotifyApi.getArtistAlbums(id, {
            album_type: "album",
            limit: 10,
            country: "GB",
          }),
          spotifyApi.getArtistAlbums(id, {
            album_type: "single,compilation",
            limit: 50,
            country: "GB",
          }),
          spotifyApi.getArtistAlbums(id, {
            album_type: "appears_on",
            limit: 50,
            country: "GB",
          }),
        ]);
        return {
          albums,
          singlesAndCompilation,
          appearsOn,
        };
      } catch (e) {
        console.error(e);
        return null;
      }
    };

    const fetchArtistData = async (id) => {
      const spotifyApi = await useSpotifyApi(store);
      try {
        loading.value = true;
        const [artistData, artistTopTracks, relatedArtists] = await Promise.all(
          [
            spotifyApi.getArtist(id),
            spotifyApi.getArtistTopTracks(id, "GB"),
            spotifyApi.getArtistRelatedArtists(id),
          ]
        );
        return {
          artistData,
          artistTopTracks,
          relatedArtists,
        };
      } catch (e) {
        console.error(e);
        loading.value = false;
        return null;
      }
    };

    const followArtist = async () => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        await spotifyApi.followArtists([props.id]);
        isArtistFollowedByUser.value = true;
      } catch (e) {
        console.error(e);
      }
    };

    const getAlbumTracks = async (uuid, album) => {
      await store.dispatch(
        "TrackPlaybackModule/setTimeOutToPlaySong",
        async (store) => {
          const spotifyApi = await useSpotifyApi(store);
          try {
            const { body } = await spotifyApi.getAlbumTracks(album.id, {
              limit: 50,
              offset: 0,
            });
            return {
              uuid,
              artistName: album.artists[0].name,
              albumName: album.name,
              imageUrl: album.images.length
                ? album.images[0].url
                : "/media/avatars/blank.png",
              trackPreviewUrl: body.items[0].preview_url,
            };
          } catch (e) {
            console.error(e);
            return {};
          }
        }
      );
    };

    const getArtistTracks = async (uuid, artist) => {
      await store.dispatch(
        "TrackPlaybackModule/setTimeOutToPlaySong",
        async (store) => {
          const spotifyApi = await useSpotifyApi(store);
          try {
            const { body } = await spotifyApi.getArtistTopTracks(
              artist.id,
              "CH"
            );
            return {
              uuid,
              artistName: artist.name,
              albumName: body.tracks[0].album.name,
              imageUrl: artist.images.length
                ? artist.images[0].url
                : "/media/avatars/blank.png",
              trackPreviewUrl: body.tracks[0].preview_url,
            };
          } catch (e) {
            console.error(e);
            return {};
          }
        }
      );
    };

    const playTrack = async (
      uuid,
      artist,
      albumName,
      images,
      trackPreviewUrl
    ) => {
      await store.dispatch("TrackPlaybackModule/setTimeOutToPlaySong", () => {
        return {
          uuid,
          artistName: artist,
          albumName,
          imageUrl: images.length ? images[0].url : "/media/avatars/blank.png",
          trackPreviewUrl,
        };
      });
    };

    const stopTrack = async () => {
      await store.dispatch("TrackPlaybackModule/stopSong");
    };

    const setValues = (artistResponse, artistAlbumsResponse) => {
      artist.value = artistResponse.artistData.body;
      topTracks.value = artistResponse.artistTopTracks.body.tracks;
      albums.value = artistAlbumsResponse.albums.body.items;
      singlesAndCompilations.value =
        artistAlbumsResponse.singlesAndCompilation.body.items;
      appearsOn.value = artistAlbumsResponse.appearsOn.body.items;
      relatedArtists.value = artistResponse.relatedArtists.body.artists;
    };

    watch(
      () => props.id,
      async (id) => {
        await getComponentData(id);
      }
    );

    return {
      artist,
      albums,
      appearsOn,
      addComponentToView,
      elementRef,
      followArtist,
      getAlbumTracks,
      getArtistTracks,
      generateUUID,
      getAvatarImage,
      isArtistFollowedByUser,
      loading,
      playTrack,
      relatedArtists,
      stopTrack,
      singlesAndCompilations,
      topTracks,
    };
  },
};
</script>

<style scoped>
.artist-details {
  margin-left: 1.5rem;
}
.tracksImages {
  margin-left: 0.2rem;
}
</style>
