const discoverMenuConfig = [
  {
    name: "New Releases",
    disabled: false,
    tooltip: {
      show: false,
    },
  },
  {
    name: "Spotify Playlists",
    disabled: false,
    tooltip: {
      show: false,
    },
  },
  {
    name: "Genres",
    disabled: false,
    tooltip: {
      show: false,
    },
  },
  {
    name: "My Albums",
    disabled: false,
    tooltip: {
      show: false,
    },
  },
  {
    name: "My Playlists",
    disabled: false,
    tooltip: {
      show: false,
    },
  },
  {
    name: "Mood",
    disabled: true,
    tooltip: {
      show: true,
      content: "Coming Soon...",
    },
  },
];

export default discoverMenuConfig;
