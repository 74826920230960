<template>
  <CardToolbar
    description="Spotify New Releases"
    title="New Releases"
    :show-toolbar-buttons="true"
  >
    <template v-slot:toolbar>
      <el-select
        @change="sortAlbums"
        clearable="true"
        v-model="selectedSortOption"
        placeholder="Select"
      >
        <el-option
          v-for="item in sortOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </template>
    <template v-slot:body>
      <el-skeleton :loading="loading" animated>
        <template #default>
          <div class="mb-5 links-container">
            <span
              v-for="(link, index) in links"
              :key="index"
              :class="[{ 'active-link': activeLink === link }, 'tab-links']"
              :ref="
                (el) => {
                  currentlySelectedButton[index] = el;
                }
              "
              @click="displaySelectedAlbums(index)"
              >{{ link }}</span
            >
          </div>
          <el-avatar
            @click="
              $emit('add-component', [
                {
                  component: 'DiscoverAlbumWithTracks',
                  id: album.id,
                },
              ])
            "
            :id="generateUUID()"
            @mouseenter="getAlbumTracks($event.target.id, album)"
            @mouseleave="stopTrack"
            v-for="(album, index) in filteredReleases"
            :key="index"
            shape="square"
            :src="getAvatarImage(album.images)"
            :size="activeLink === 'All' ? 60 : 90"
            class="cursor-pointer avatar"
          />
        </template>
      </el-skeleton>
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import CardToolbar from "../../common/components/CardToolbar";
import useSpotifyApi from "../../common/composables/useSpotifyApi";
import useUUID from "../../common/composables/useUUID";
import useAvatarImage from "../../common/composables/useAvatarImage";

export default {
  components: { CardToolbar },
  name: "NewReleases",

  setup() {
    const activeLink = ref("All");
    const currentlySelectedButton = ref([]);
    const filteredReleases = ref([]);
    const { generateUUID } = useUUID();
    const { getAvatarImage } = useAvatarImage();
    const newReleases = ref([]);
    const loading = ref(false);
    const links = ["All", "Albums", "Singles", "Compilations"];
    const store = useStore();
    const selectedSortOption = ref("");

    onMounted(async () => {
      await getNewReleases();
    });

    const displaySelectedAlbums = (index) => {
      selectedSortOption.value = "";
      activeLink.value = currentlySelectedButton.value[index].textContent;
      if (activeLink.value === "All") {
        filteredReleases.value = [...newReleases.value];
        return;
      }
      const albumType = activeLink.value
        .substr(0, activeLink.value.length - 1)
        .toLowerCase();
      filteredReleases.value = newReleases.value.filter(
        (album) => album.album_type === albumType
      );
    };

    const getAlbumTracks = async (uuid, album) => {
      await store.dispatch(
        "TrackPlaybackModule/setTimeOutToPlaySong",
        async (store) => {
          const spotifyApi = await useSpotifyApi(store);
          try {
            const { body } = await spotifyApi.getAlbumTracks(album.id, {
              limit: 50,
              offset: 0,
            });
            return {
              uuid,
              artistName: album.artists[0].name,
              albumName: album.name,
              imageUrl: getAvatarImage(album.images),
              trackPreviewUrl: body.items[0].preview_url,
            };
          } catch (e) {
            console.error(e);
            return {};
          }
        }
      );
    };

    const getNewReleases = async () => {
      try {
        loading.value = true;
        const spotifyApi = await useSpotifyApi(store);
        const promises = [
          spotifyApi.getNewReleases({ country: "CH", limit: 50, offset: 0 }),
          spotifyApi.getNewReleases({ country: "CH", limit: 50, offset: 51 }),
        ];
        const albumResponses = await Promise.all(promises);
        let albums = [];
        albumResponses.forEach((albumResponse) => {
          albums.push(albumResponse.body.albums.items);
        });
        newReleases.value = albums.flat();
        filteredReleases.value = albums.flat();
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    const sortAlbums = () => {
      switch (selectedSortOption.value) {
        case "album":
          filteredReleases.value.sort((a, b) =>
            a.name === b.name ? 0 : a.name > b.name ? 1 : -1
          );
          break;
        case "releaseDate":
          filteredReleases.value.sort((a, b) => {
            const dateA = new Date(a.release_date);
            const dateB = new Date(b.release_date);
            return dateA === dateB ? 0 : dateA > dateB ? 1 : -1;
          });
          break;
        case "numberOfTracks":
          filteredReleases.value.sort(
            (a, b) => a.total_tracks - b.total_tracks
          );
          break;
        case "numberOfArtists":
          filteredReleases.value.sort(
            (a, b) => a.artists.length - b.artists.length
          );
          break;
        default:
          filteredReleases.value = [...newReleases.value];
      }
    };

    const stopTrack = async () => {
      await store.dispatch("TrackPlaybackModule/stopSong");
    };

    return {
      activeLink,
      currentlySelectedButton,
      filteredReleases,
      getAlbumTracks,
      generateUUID,
      getAvatarImage,
      displaySelectedAlbums,
      loading,
      links,
      newReleases,
      sortAlbums,
      stopTrack,
      sortOptions: ref([
        {
          value: "album",
          label: "Sort by album",
        },
        {
          value: "releaseDate",
          label: "Sort by release date (recent to old)",
        },
        {
          value: "numberOfTracks",
          label: "Sort by number of tracks (most to least)",
        },
        {
          value: "numberOfArtists",
          label: "Sort by number of artists (most to least)",
        },
      ]),
      selectedSortOption,
    };
  },
};
</script>

<style scoped>
.avatar {
  border-radius: 0 !important;
}
.tab-links {
  cursor: pointer;
  font-size: 1rem;
  color: #777;
}
.tab-links:hover {
  text-decoration: underline;
  color: #333;
}
.links-container .tab-links:not(:first-child) {
  margin-left: 1.5rem;
}
.active-link {
  color: #333;
}
</style>
