<template>
  <el-tooltip placement="top" :content="tooltip.content" v-if="tooltip.show">
    <div class="d-inline">
      <a
        :class="[
          { active: currentlySelectedMenu === name, disabled: disabled },
          'btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ',
        ]"
        @click="onHandleClick"
        >{{ name }}</a
      >
    </div>
  </el-tooltip>
  <a
    v-else
    :class="[
      { active: currentlySelectedMenu === name, disabled: disabled },
      'btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ',
    ]"
    @click="onHandleClick"
    >{{ name }}</a
  >
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  emits: ["update:selectedItem"],
  name: "DiscoverMenuItem",
  props: {
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Object,
      default: () => {
        return {
          show: false,
        };
      },
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const onHandleClick = (e) => {
      store.dispatch("DiscoverModule/changeSelectedMenu", e.target.innerText);
      router.push("/discover");
    };
    const currentlySelectedMenu = computed(
      () => store.getters["DiscoverModule/getCurrentlySelectedMenu"]
    );
    return { onHandleClick, currentlySelectedMenu };
  },
};
</script>

<style scoped></style>
