<template>
  <div class="action-panel">
    <el-tooltip :content="content" placement="top-start">
      <div
        v-loading="loading"
        class="action-panel-icons"
        v-on="isFollowed === false ? { click: follow } : {}"
      >
        <span class="svg-icon svg-icon-2 svg-primary">
          <inline-svg :src="icon" />
        </span></div
    ></el-tooltip>
    <el-tooltip
      v-if="showPlaylistButton"
      content="Add to Playlist"
      placement="right"
    >
      <div class="action-panel-icons" @click="showPlaylistModal">
        <span class="svg-icon svg-icon-2 svg-primary">
          <inline-svg src="/media/icons/add_to_playlist_white.svg" />
        </span>
      </div>
    </el-tooltip>
    <el-tooltip content="Open in Spotify" placement="bottom-start">
      <div class="action-panel-icons" @click="redirectToSpotify">
        <span class="svg-icon svg-icon-2 svg-primary">
          <inline-svg src="/media/icons/open_in_spotify_white.svg" />
        </span></div
    ></el-tooltip>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "DiscoverActionPanel",
  props: {
    content: {
      type: String,
      required: true,
    },
    isFollowed: {
      type: Boolean,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      default: process.env.VUE_APP_API_URL,
    },
    showPlaylistButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const addToPlaylistModal = ref(null);
    const loading = ref(false);

    const follow = async () => {
      context.emit("follow");
    };

    const redirectToSpotify = () => {
      window.open(props.url, "_blank");
    };

    const showPlaylistModal = async () => {
      context.emit("open");
    };

    return {
      addToPlaylistModal,
      follow,
      loading,
      redirectToSpotify,
      showPlaylistModal,
    };
  },
};
</script>

<style scoped lang="scss">
.action-panel {
  border-radius: 0.475rem;
  background-color: rgba(0, 0, 0, 0.6);
  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
.action-panel-icons {
  cursor: pointer;
  padding: 0.3rem;
}
</style>
