<template>
  <CardToolbar
    :show-toolbar-buttons="false"
    title="My Albums"
    description="Your Saved Albums"
  >
    <template v-slot:body>
      <el-skeleton :loading="loading" animated>
        <template #default>
          <el-avatar
            @click="
              $emit('add-component', [
                {
                  component: 'DiscoverAlbumWithTracks',
                  id: albums.album.id,
                },
              ])
            "
            :id="generateUUID()"
            @mouseenter="getAlbumTracks($event.target.id, albums.album)"
            @mouseleave="stopTrack"
            v-for="(albums, index) in myAlbums"
            :key="index"
            shape="square"
            :src="getAvatarImage(albums.album.images)"
            :size="60"
            class="cursor-pointer avatar"
          />
        </template>
      </el-skeleton>
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import CardToolbar from "../../common/components/CardToolbar";
import useSpotifyApi from "../../common/composables/useSpotifyApi";
import useUUID from "../../common/composables/useUUID";
import useAvatarImage from "../../common/composables/useAvatarImage";
export default {
  name: "MyAlbums",
  components: { CardToolbar },
  setup() {
    const { generateUUID } = useUUID();
    const { getAvatarImage } = useAvatarImage();
    const loading = ref(true);
    const myAlbums = ref([]);
    const store = useStore();

    onMounted(async () => {
      await getMyAlbums();
    });

    const getMyAlbums = async () => {
      try {
        loading.value = true;
        const spotifyApi = await useSpotifyApi(store);
        const limit = 50;
        const { body } = await spotifyApi.getMySavedAlbums({
          market: "CH",
          limit,
        });
        myAlbums.value = body.items;
        if (body.total > limit) {
          await getPaginatedData(limit, body.total);
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    const getPaginatedData = async (limit, total) => {
      const spotifyApi = await useSpotifyApi(store);
      let promises = [];
      let remainingEntries = total - limit;
      let offset = limit;
      while (remainingEntries > 0) {
        const promise = spotifyApi.getMySavedAlbums({
          offset,
          limit: 50,
        });
        promises.push(promise);
        offset += limit;
        remainingEntries -= limit;
      }
      const remainingAlbums = await Promise.all(promises);
      remainingAlbums.forEach((res) => {
        myAlbums.value = [...myAlbums.value, res.body.items];
      });
      myAlbums.value = myAlbums.value.flat();
    };

    const getAlbumTracks = async (uuid, album) => {
      await store.dispatch(
        "TrackPlaybackModule/setTimeOutToPlaySong",
        async (store) => {
          const spotifyApi = await useSpotifyApi(store);
          try {
            const { body } = await spotifyApi.getAlbumTracks(album.id, {
              limit: 50,
              offset: 0,
            });
            return {
              uuid,
              artistName: getArtistName(album.artists),
              albumName: album.name,
              imageUrl: getAvatarImage(album.images),
              trackPreviewUrl: body.items[0].preview_url,
            };
          } catch (e) {
            console.error(e);
            return {};
          }
        }
      );
    };

    const getArtistName = (artists) => {
      let artistName = "";
      artists.forEach((artist, index) => {
        artistName += `${artist.name}${index < artists.length - 1 ? "," : ""}`;
      });
      return artistName;
    };

    const stopTrack = async () => {
      await store.dispatch("TrackPlaybackModule/stopSong");
    };

    return {
      myAlbums,
      getMyAlbums,
      getAlbumTracks,
      generateUUID,
      getAvatarImage,
      loading,
      stopTrack,
    };
  },
};
</script>

<style scoped>
.avatar {
  border-radius: 0 !important;
}
</style>
