<template>
  <template v-for="(menuConfig, index) in discoverMenuConfig" :key="index">
    <DiscoverMenuItem
      :name="menuConfig.name"
      :disabled="menuConfig.disabled"
      :tooltip="menuConfig.tooltip"
    />
  </template>
</template>

<script>
import { ref } from "vue";

import DiscoverMenuItem from "./DiscoverMenuItem";
import discoverMenuConfig from "../../core/config/DiscoverMenuConfig";
export default {
  name: "DiscoverMenu",
  emits: ["update:query"],
  components: { DiscoverMenuItem },
  setup() {
    const selectedItem = ref("Genres");

    return {
      discoverMenuConfig,
      selectedItem,
    };
  },
};
</script>

<style scoped></style>
