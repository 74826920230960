<template>
  <CardToolbar
    id="searchDiv"
    v-if="query"
    :title="query"
    description="Your Search Results"
    :show-toolbar-buttons="false"
  >
    <template v-slot:body>
      <el-skeleton :loading="loading" animated>
        <template #default>
          <el-row :gutter="20">
            <el-col :span="12">
              <span class="subheading">SONGS</span>
              <SpotifySearchResult
                @click="
                  addComponentToView([
                    { component: 'DiscoverAlbum', id: track.id },
                    {
                      component: 'DiscoverArtistDetail',
                      id: track.artists[0].id,
                    },
                  ])
                "
                :id="generateUUID()"
                @on-hover-enter="
                  getTrack(
                    $event.target.id,
                    track.id,
                    getAvatarImage(track.album.images)
                  )
                "
                @on-hover-leave="hideTrackComponent"
                v-for="track in tracks"
                :key="track.id"
                :name="track.name"
                :artist-names="track.artists"
                :image-url="getAvatarImage(track.album.images)"
              />
            </el-col>
            <el-col :span="12">
              <span class="subheading">ARTISTS</span>
              <SpotifySearchResult
                :id="generateUUID()"
                @click="
                  addComponentToView([
                    { component: 'DiscoverArtistDetail', id: artist.id },
                  ])
                "
                @on-hover-enter="
                  getArtistTopTracks(
                    $event.target.id,
                    artist.id,
                    getAvatarImage(artist.images)
                  )
                "
                @on-hover-leave="hideTrackComponent"
                v-for="artist in artists"
                :key="artist.id"
                :name="artist.name"
                image-shape="circle"
                :image-url="getAvatarImage(artist.images)"
              />
            </el-col>
          </el-row>

          <el-row class="mt-5 mb-5" :gutter="20">
            <el-col :span="12">
              <span class="subheading">ALBUMS</span>

              <SpotifySearchResult
                @click="
                  addComponentToView([
                    {
                      component: 'DiscoverAlbumWithTracks',
                      id: album.id,
                    },
                  ])
                "
                :id="generateUUID()"
                @on-hover-enter="
                  getAlbumTracks(
                    $event.target.id,
                    album.id,
                    getAvatarImage(album.images)
                  )
                "
                @on-hover-leave="hideTrackComponent"
                v-for="album in albums"
                :key="album.id"
                :name="album.name"
                :image-url="getAvatarImage(album.images)"
                :artist-names="album.artists"
              />
            </el-col>
            <el-col :span="12">
              <span class="subheading">PLAYLISTS</span>
              <SpotifySearchResult
                @click="
                  addComponentToView([
                    { component: 'DiscoverPlaylist', id: playlist.id },
                  ])
                "
                :id="generateUUID()"
                @on-hover-enter="
                  getPlaylistTracks(
                    $event.target.id,
                    playlist.id,
                    getAvatarImage(playlist.images)
                  )
                "
                @on-hover-leave="hideTrackComponent"
                v-for="playlist in playlists"
                :key="playlist.id"
                :name="playlist.name"
                :image-url="getAvatarImage(playlist.images)"
              />
            </el-col>
          </el-row>
        </template>
      </el-skeleton>
    </template>
  </CardToolbar>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

import CardToolbar from "../../common/components/CardToolbar";
import SpotifySearchResult from "./SpotifySearchResult";
import useSpotifyApi from "../../common/composables/useSpotifyApi";
import useUUID from "../../common/composables/useUUID";
import useAvatarImage from "../../common/composables/useAvatarImage";

export default {
  name: "Search",
  components: { SpotifySearchResult, CardToolbar },
  emits: ["addComponent"],
  props: {
    query: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const store = useStore();
    const albums = ref([]);
    const tracks = ref([]);
    const playlists = ref([]);
    const artists = ref([]);
    const loading = ref(false);
    const showTrackComponent = ref(false);
    const trackPreviewUrl = ref("");
    const imageUrl = ref("");
    const { generateUUID } = useUUID();
    const { getAvatarImage } = useAvatarImage();

    const addComponentToView = async (componentData) => {
      context.emit("add-component", componentData);
    };

    const spotifySearchResult = async (searchQuery) => {
      loading.value = true;
      const spotifyApi = await useSpotifyApi(store);
      try {
        const { body } = await spotifyApi.search(
          searchQuery,
          ["album", "artist", "playlist", "track"],
          { limit: 5, offset: 0 }
        );
        albums.value = body.albums.items;
        tracks.value = body.tracks.items;
        playlists.value = body.playlists.items;
        artists.value = body.artists.items;
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    const getArtistTopTracks = async (uuid, artistId, imgUrl) => {
      await store.dispatch(
        "TrackPlaybackModule/setTimeOutToPlaySong",
        async (store) => {
          const spotifyApi = await useSpotifyApi(store);
          try {
            const { body } = await spotifyApi.getArtistTopTracks(
              artistId,
              "CH"
            );
            trackPreviewUrl.value = body.tracks[0]
              ? body.tracks[0].preview_url
              : null;
            imageUrl.value = imgUrl;
            const albumName = body.tracks.length
              ? body.tracks[0].album.name
              : "";
            const artistName = body.tracks[0]
              ? getArtistName(body.tracks[0].artists)
              : "";
            return {
              uuid,
              artistName,
              albumName,
              imageUrl: imageUrl.value,
              trackPreviewUrl: trackPreviewUrl.value,
            };
          } catch (e) {
            console.error(e);
          }
          return {};
        }
      );
    };

    const getAlbumTracks = async (uuid, albumId, imgUrl) => {
      await store.dispatch(
        "TrackPlaybackModule/setTimeOutToPlaySong",
        async (store) => {
          const spotifyApi = await useSpotifyApi(store);
          try {
            const { body } = await spotifyApi.getAlbumTracks(albumId, {
              limit: 50,
              offset: 0,
            });
            trackPreviewUrl.value = body.items[0]
              ? body.items[0].preview_url
              : null;
            imageUrl.value = imgUrl;
            const albumName = body.items[0] ? body.items[0].name : null;
            const artistName = body.items[0]
              ? getArtistName(body.items[0].artists)
              : "";
            return {
              uuid,
              artistName,
              albumName,
              imageUrl: imageUrl.value,
              trackPreviewUrl: trackPreviewUrl.value,
            };
          } catch (e) {
            console.error(e);
          }
          return {};
        }
      );
    };

    const getPlaylistTracks = async (uuid, playlistId, imgUrl) => {
      await store.dispatch(
        "TrackPlaybackModule/setTimeOutToPlaySong",
        async (store) => {
          const spotifyApi = await useSpotifyApi(store);
          try {
            const { body } = await spotifyApi.getPlaylistTracks(playlistId, {
              limit: 50,
              offset: 0,
            });
            trackPreviewUrl.value =
              body.items[0] && body.items[0].track
                ? body.items[0].track.preview_url
                : null;
            imageUrl.value = imgUrl;
            const albumName =
              body.items[0] && body.items[0].track && body.items[0].track.album
                ? body.items[0].track.album.name
                : null;
            const artistName = body.items[0].track
              ? getArtistName(body.items[0].track.artists)
              : null;
            return {
              uuid,
              artistName,
              albumName,
              imageUrl: imageUrl.value,
              trackPreviewUrl: trackPreviewUrl.value,
            };
          } catch (e) {
            console.error(e);
          }
          return {};
        }
      );
    };

    const getTrack = async (uuid, trackId, imgUrl) => {
      await store.dispatch(
        "TrackPlaybackModule/setTimeOutToPlaySong",
        async (store) => {
          const spotifyApi = await useSpotifyApi(store);
          try {
            const { body } = await spotifyApi.getTrack(trackId);
            trackPreviewUrl.value = body.preview_url;
            imageUrl.value = imgUrl;
            const albumName = body.album.name;
            const artistName = getArtistName(body.album.artists);
            return {
              uuid,
              artistName,
              albumName,
              imageUrl: imageUrl.value,
              trackPreviewUrl: trackPreviewUrl.value,
            };
          } catch (e) {
            console.error(e);
          }
          return {};
        }
      );
    };

    const getArtistName = (artist) => {
      let artistName = "";
      for (let i = 0; i < artist.length; i++) {
        artistName += artist[i].name;
      }
      return artistName;
    };

    const hideTrackComponent = async () => {
      await store.dispatch("TrackPlaybackModule/stopSong");
    };

    onMounted(() => {
      if (props.query !== "") {
        spotifySearchResult(props.query);
      }
    });

    watch(
      () => props.query,
      (searchQuery) => {
        spotifySearchResult(searchQuery);
      }
    );

    return {
      artists,
      albums,
      addComponentToView,
      getArtistTopTracks,
      getAlbumTracks,
      getPlaylistTracks,
      getTrack,
      generateUUID,
      getAvatarImage,
      hideTrackComponent,
      imageUrl,
      loading,
      playlists,
      showTrackComponent,
      tracks,
      trackPreviewUrl,
    };
  },
};
</script>

<style scoped></style>
