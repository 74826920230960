<template>
  <CardToolbar
    title="Genres"
    description="Genres of different categories"
    :show-toolbar-buttons="true"
  >
    <template v-slot:toolbar>
      <input
        class="form-control form-control-solid me-3"
        placeholder="Filter"
        v-model="filterQuery"
        @input="onFilterGenres"
      />
    </template>
    <template v-slot:body>
      <el-skeleton :loading="loading" animated>
        <template #default>
          <div class="genres">
            <el-row>
              <el-col :span="8" v-for="genre in filteredGenres" :key="genre.id">
                <h6
                  @click="
                    $emit('add-component', [
                      {
                        component: 'DiscoverPlaylist',
                        id: genre.id,
                      },
                    ])
                  "
                  class="cursor-pointer genre-name"
                >
                  {{ genre.name }}
                </h6>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-skeleton>
    </template>
  </CardToolbar>
</template>

<script>
import { ref, onMounted } from "vue";

import CardToolbar from "../../common/components/CardToolbar";
import { getAllGenres } from "@/api/genreResult";

export default {
  name: "Genres",
  components: { CardToolbar },
  setup() {
    const genres = ref([]);
    const noOfItemsPerRow = ref(0);
    const filteredGenres = ref([]);
    const noOfRows = ref(3);
    const filterQuery = ref("");
    const loading = ref(true);
    const error = ref(false);

    onMounted(async () => {
      try {
        const { data } = await getAllGenres();
        genres.value = data;
        noOfItemsPerRow.value = genres.value.length / noOfRows.value;
        filteredGenres.value = genres.value;
      } catch (e) {
        error.value = true;
        console.error(e);
      } finally {
        loading.value = false;
      }
    });

    const onFilterGenres = () => {
      filteredGenres.value = genres.value.filter((genre) =>
        genre.name.includes(filterQuery.value)
      );
    };

    return { filterQuery, filteredGenres, loading, onFilterGenres };
  },
};
</script>

<style scoped>
.genres {
  height: 25rem !important;
  overflow-y: scroll;
}
.genre-name:hover {
  text-decoration: underline;
}
</style>
