<template>
  <div
    v-loading="loading"
    style="top: 30%"
    class="
      card
      mb-5 mb-xl-10
      p-2
      d-flex
      justify-content-center
      align-items-center
      position-sticky
      player
    "
    v-if="(playerData && playerData.artistName) || loading"
  >
    <div class="card-body pt-9 pb-0">
      <el-image
        :src="loading ? '/media/avatars/blank.png' : playerData.imageUrl"
        fit="fill"
      ></el-image>
    </div>
    <div
      v-if="playerData && playerData.artistName"
      style="width: 100%"
      class="px-10 mt-5 mb-5"
    >
      <span class="subheading fw-bolder fs-3">{{ playerData.albumName }}</span
      ><br />
      <span class="subheading">{{ playerData.artistName }}</span>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "TrackPlayer",
  setup() {
    const store = useStore();
    const playerData = computed(() => {
      return store.getters["TrackPlaybackModule/getPlayerData"];
    });
    const loading = computed(() => {
      return store.getters["TrackPlaybackModule/getPlayerLoading"];
    });
    return {
      playerData,
      loading,
    };
  },
};
</script>

<style scoped></style>
