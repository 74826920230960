<template>
  <Playlists
    card-description="User's Spotify Playlists"
    card-title="Spotify Playlists"
    :playlist-data="playlists"
  />
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

import Playlists from "./Playlists";
import useSpotifyApi from "../../common/composables/useSpotifyApi";

export default {
  name: "SpotifyPlaylists",
  components: { Playlists },
  setup() {
    const loading = ref(true);
    const playlists = ref([]);
    const store = useStore();

    onMounted(async () => {
      await getCategories();
    });

    const getCategories = async () => {
      loading.value = true;
      const spotifyApi = await useSpotifyApi(store);
      const { body } = await spotifyApi.getCategories({
        country: "CH",
        limit: 50,
      });
      const categories = body.categories.items.map((category) => {
        return {
          id: category.id,
          name: category.name,
        };
      });
      await getPlaylistsByCategory(categories);
    };

    const getPlaylistsByCategory = async (categories) => {
      let promises = [];
      const spotifyApi = await useSpotifyApi(store);
      categories.forEach((category) => {
        const promise = spotifyApi.getPlaylistsForCategory(category.id, {
          country: "CH",
          limit: 50,
        });
        promises.push(promise);
      });
      const data = await Promise.all(promises);
      playlists.value = data.map((playlist, index) => {
        return {
          category: categories[index].name,
          playlists: playlist.body.playlists.items,
        };
      });
      loading.value = false;
    };

    return {
      playlists,
      loading,
    };
  },
};
</script>

<style scoped></style>
