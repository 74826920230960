<template>
  <div class="d-flex justify-content-center" :id="id + componentIndex">
    <div class="card flex-grow-1">
      <div class="card-body d-flex justify-content-between">
        <el-skeleton :loading="loading">
          <template #default>
            <div
              class="d-flex cursor-pointer"
              @mouseenter="
                playTrack(
                  $event.target.id,
                  albumData.album.artists.length
                    ? albumData.album.artists[0].name
                    : 'N/A',
                  albumData.name,
                  getAvatarImage(albumData.album.images),
                  albumData.preview_url
                )
              "
              @mouseleave="stopTrack"
              :id="generateUUID()"
            >
              <div>
                <el-avatar
                  fit="fill"
                  shape="square"
                  :size="100"
                  :src="getAvatarImage(albumData.album.images)"
                ></el-avatar>
              </div>
              <div class="album-details">
                <h3>{{ albumData.name }}</h3>
                <span>
                  {{
                    `From the ${albumData.album.album_type} ${
                      albumData.album.name
                    } (${new Date(
                      albumData.album.release_date
                    ).getFullYear()}) `
                  }}
                </span>
                <div>
                  <span>By {{ " " }}</span>
                  <span
                    v-for="(artist, index) in albumData.album.artists"
                    :key="index"
                    >{{ `${artist.name}` }}</span
                  >
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>
      </div>
    </div>
    <div v-if="!loading">
      <DiscoverActionPanel
        :is-followed="isTrackSavedByUser"
        :url="albumData.external_urls.spotify"
        :content="
          isTrackSavedByUser ? 'You saved this track' : 'Save this track'
        "
        @follow="addToMySavedTracks"
        @open="openPlaylistModal"
        :icon="
          isTrackSavedByUser
            ? '/media/icons/followed.svg'
            : '/media/icons/save_white.svg'
        "
        :show-playlist-button="true"
      />

      <AddToPlaylistModal
        ref="addToPlaylistModal"
        @add-track="addTrackToPlaylist"
        @create-playlist="createPlaylist"
      />
    </div>
    <button
      class="visually-hidden"
      ref="modalButton"
      data-bs-toggle="modal"
      data-bs-target="#playlist_modal"
    >
      Open Modal
    </button>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

import AddToPlaylistModal from "./AddToPlaylistModal";
import useSpotifyApi from "../../common/composables/useSpotifyApi";
import DiscoverActionPanel from "./DiscoverActionPanel";
import { ElNotification } from "element-plus";
import useUUID from "../../common/composables/useUUID";
import useAvatarImage from "../../common/composables/useAvatarImage";

export default {
  name: "DiscoverAlbum",
  components: { AddToPlaylistModal, DiscoverActionPanel },
  props: {
    id: {
      type: String,
      required: true,
    },
    componentIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const albumData = ref({});
    const addToPlaylistModal = ref(null);
    const { generateUUID } = useUUID();
    const { getAvatarImage } = useAvatarImage();
    const isTrackSavedByUser = ref(false);
    const loading = ref(true);
    const modalButton = ref(null);
    const store = useStore();

    onMounted(async () => {
      await fetchAlbumData(props.id);
    });

    const addTrackToPlaylist = async (playlistId) => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        await spotifyApi.addTracksToPlaylist(playlistId, [
          `spotify:track:${props.id}`,
        ]);
        modalButton.value.click();
        playlistSuccessMessage();
      } catch (e) {
        console.error(e);
        playlistErrorMessage();
      }
    };

    const addToMySavedTracks = async () => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        await spotifyApi.addToMySavedTracks([props.id]);
        isTrackSavedByUser.value = true;
      } catch (e) {
        console.error(e);
      }
    };
    const createPlaylist = async (playlistName) => {
      try {
        const spotifyApi = await useSpotifyApi(store);
        const { body } = await spotifyApi.createPlaylist(playlistName);
        await addTrackToPlaylist(body.id);
      } catch (e) {
        console.error(e);
        playlistErrorMessage();
      }
    };
    const checkIsTrackSavedByUser = async (trackId) => {
      const spotifyApi = await useSpotifyApi(store);
      const { body } = await spotifyApi.containsMySavedTracks([trackId]);
      isTrackSavedByUser.value = body[0];
    };

    const fetchAlbumData = async (id) => {
      try {
        loading.value = true;
        const spotifyApi = await useSpotifyApi(store);
        const { body } = await spotifyApi.getTrack(id);
        await checkIsTrackSavedByUser(id);
        albumData.value = body;
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
        store.dispatch(
          "DiscoverModule/scrollToElement",
          props.id + props.componentIndex
        );
      }
    };

    const openPlaylistModal = () => {
      modalButton.value.click();
      addToPlaylistModal.value.fetchUserPlaylists();
    };

    const playlistSuccessMessage = () => {
      ElNotification({
        title: "Success",
        message: "Track added to playlist.",
        type: "success",
      });
    };

    const playlistErrorMessage = () => {
      ElNotification({
        title: "Error",
        message: "Track not added",
        type: "error",
      });
    };

    const playTrack = async (
      uuid,
      artist,
      albumName,
      images,
      trackPreviewUrl
    ) => {
      await store.dispatch("TrackPlaybackModule/setTimeOutToPlaySong", () => {
        return {
          uuid,
          artistName: artist,
          albumName,
          imageUrl: images,
          trackPreviewUrl,
        };
      });
    };

    const stopTrack = async () => {
      await store.dispatch("TrackPlaybackModule/stopSong");
    };

    watch(
      () => props.id,
      async (id) => {
        await fetchAlbumData(id);
      }
    );

    return {
      addToPlaylistModal,
      albumData,
      addToMySavedTracks,
      addTrackToPlaylist,
      createPlaylist,
      generateUUID,
      getAvatarImage,
      isTrackSavedByUser,
      loading,
      modalButton,
      openPlaylistModal,
      playTrack,
      stopTrack,
    };
  },
};
</script>

<style scoped>
.album-details {
  margin-left: 1rem;
}
</style>
